<!--
 * @Author: LZH
 * @Date: 2025-03-04 12:09:18
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-03-13 18:11:02
 * @Description: 
-->
<template>
  <div
    class="box"
    :style="$store.state.displayArea.rightWidth + $store.state.displayArea.height"
  >
    <div class="baidu-api" id="googlemap" v-show="mapType == 'Wgs84'"></div>
    <div class="search flex_align" v-if="!$store.state.isCustom">
      <div class="search_input flex_align">
        <el-autocomplete
          id="suggestId"
          style="width: 100%"
          @focus="focus"
          @blur="blur"
          v-model="search"
          :fetch-suggestions="querySearchAsync"
          :trigger-on-focus="false"
          :placeholder="$t('message.SearchLocation')"
          @select="handleSelect"
        >
          <template slot-scope="{ item }">
            <div class="flex_align search_title" style="width: 100%">
              <i class="el-icon-search fl mgr10" style="margin-right: 10px"></i>
              <div class="flex_align">
                {{ item.title }} <span v-if="item.address">({{ item.address }})</span>
              </div>
            </div>
          </template>
        </el-autocomplete>
        <div class="search_x mouse" @click="eliminate">×</div>
      </div>
      <div class="search_but mouse" @click="query()">
        <img src="@/assets/group/search.png" alt="" />
      </div>
    </div>
    <halfBall ref="halfball" @selectedBall="selectedBall"></halfBall>
    <mapcustomfence
      ref="mapcustomfence"
      :markList="markList"
      @openDrawing="openDrawing"
      @drawPolylines="drawPolylines"
      @drawCircle="drawCircle"
      @drawRectangle="drawRectangle"
      @previousStep="previousStep"
      @deleteFenceArea="deleteFenceArea"
      @switchOnlineState="switchOnlineState"
      @changeMap="changeMap"
    >
    </mapcustomfence>
    <addGroup
      ref="addGroup"
      v-if="isBool"
      :dialogTitle="dialogTitle"
      @clearOverwrites="clearOverwrites"
      :efId="$store.state.efId"
      :overlays="overlays"
    ></addGroup>
    <msgTip
      :showcontent="msgTipData.showcontent"
      :tipstext="msgTipData.tipstext"
    ></msgTip>
    <upmarker
      ref="upmarker"
      :markerUser="markerUser"
      v-if="dialogModifyMarker"
      @successMarker="successMarker"
      @cloneMarker="cloneMarker"
    ></upmarker>
    <mapmark
      v-if="dialogModifyMark"
      @successMark="successMark"
      @cloneMark="cloneMark"
      :mark="mark"
      ref="mapmark"
    >
    </mapmark>
    <enclosure
      ref="enclosure"
      v-if="isEnclosure"
      :dialogTitle="dialogTitle"
      @successEnclosure="successEnclosure"
      @cloneEnclosure="cloneEnclosure"
      :efId="fenceId"
      :overlays="overlays"
    ></enclosure>
    <div
      id="mouseOffset"
      v-if="editRegion != 1"
      :style="{
        position: 'absolute',
        'z-index': '9999999',
        top: offsetTop + 'px',
        left: offsetLeft + 'px',
        display: isShowMouse,
      }"
    >
      {{ $t("message.doubleclick")
      }}{{ editRegion == 3 ? $t("message.Create") : $t("message.Save") }}
    </div>
  </div>
</template>

<script src=""></script>
<script>
// import axios from "axios";
// import qs from "qs";
import mapcustomfence from "@/components/mapcustomfence.vue";
import msgTip from "@/components/history/deletepop.vue";
import enclosure from "@/components/enclosure/establishEnclosure.vue";
import addGroup from "@/components/dispatchLntercom/addGroup.vue";
import upmarker from "@/components/map/upmarker.vue";
import mapmark from "@/components/map/mapmark.vue";
import halfBall from "@/components/map/halfBall.vue";
import filterMethod from "@/method/filterMethod.js";
import { drawGraphic } from "@/mixin/map/drawGraphic.js";
import { googlemap } from "@/mixin/map/googlemap";
import { markersos } from "@/mixin/map/markersos";
import { markerusers } from "@/mixin/map/markeruser";
import { _throttle } from "@/method/utils.js";
// console.log("gpsUrlapi", gpsUrlapi);
export default {
  name: "googleMap",
  components: {
    mapcustomfence,
    msgTip,
    enclosure,
    addGroup,
    upmarker,
    mapmark,
    halfBall,
  },
  props: {},
  mixins: [drawGraphic, googlemap, markersos, markerusers],
  data() {
    return {};
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {
    switchOnlineState(type, name) {
      if (name) {
        this.$store.state.recordLable = [];
      }
      this.clearTimer();
      //切换在线状态
      this.coordinate = []; //每次更新数据都清空
      let mapMarkerIndex = this.$store.state.mapMarkerIndex;
      let isDd = this.$store.state.navId == 1;
      let arr = [];
      let userArray;
      let uid;
      let selectedInfo = this.$store.state.selectedInfo;
      let array = selectedInfo.length > 0 || !isDd ? selectedInfo : this.$store.state.groupUserData; //初始拿不到值的时候拿缓存值。
      if (isDd && mapMarkerIndex == 1 && selectedInfo.length > 0) {
        //首先判断是否有点击名称
        let Gid = selectedInfo[0].Gid ? selectedInfo[0].Gid : selectedInfo[0].gid;
        array = [this.$store.state.groupUserData.find((item) => item.Gid == Gid)]; //选中群组
        if (!selectedInfo[0].children) {
          //选中当个用户的时候
          array = [array[0].children.find((item) => selectedInfo[0].id == item.id)];
        }
      }
      if (array.length > 0 && array[0] && array[0].children) {
        array.forEach((item) => {
          // console.log(item);
          arr.push(...item.children);
        });
      } else {
        arr = array;
      }
      if (type == 0 || this.recordType == 1 || array[0] && !array[0].children) {
        //!array[0].children 判断点击的是群组还是用户
        //全部
        userArray = filterMethod.deweight(arr);
        uid = userArray.map((item) => item.id);
      } else if (type == 1) {
        //在线人数
        userArray = filterMethod.deweight(arr).filter((item) => item.online == 3);
        uid = userArray.map((item) => item.id);
      } else {
        //不在线人
        userArray = filterMethod.deweight(arr).filter((item) => item.online != 3);
        uid = userArray.map((item) => item.id);
      }
      // }
      this.type = type;
      this.removeMarkers();
      this.pointPosition = [];
      this.coordinate = filterMethod.deweight(arr).filter((item) => item.online == 3);
      this.getMarkList();
      let bool =
        selectedInfo[0] &&
        selectedInfo[0].children &&
        selectedInfo[0].children.length > 0;
      if (userArray.length > 0 || mapMarkerIndex === 0) {
        if (mapMarkerIndex === 0 && isDd) {
          this.getSosMarkerLocation();
        } else {
          this.getAnchorPoint(uid, userArray);
        }
      } else if (this.$store.state.efId > 0 && bool) {
        this.getRegionDetails();
      } else if (this.fenceId > 0 && bool) {
        this.getFenceDetails();
      }
      this.startTime();
    },
    focus() {
      //获取焦点
      this.$store.state.isFocusing = true;
    },
    blur() {
      //失去焦点
      this.$store.state.isFocusing = false;
    },
  },
  watch: {
    //监听事件
    "$store.state.groupUserData": {
      handler(newValue) {
        if (this.$store.state.navId == 1 && newValue.length > 0 && this.frequency == 0) {
          this.frequency = 1;
          this.preventFrequentlyRefresh();
        }
      },
      // deep: true,
      immediate: true,
    },
    "$store.state.mapMarkerIndex"() {
      if (this.$store.state.navId == 1) {
        this.preventFrequentlyRefresh();
      }
    },
  },
};
</script>
<style scoped lang="less">
.box {
  overflow: hidden;
}

.baidu-api {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

/* 去除百度地图版权那行字 和 百度logo */
.baidumap > .BMap_cpyCtrl {
  display: none !important;
}

// .BMap_noprint {
//   display: block !important;
// }

.anchorBL {
  display: none;
}

.search {
  width: 455px;
  position: absolute;
  top: 10px;
  left: 10px;
  // width: 418px;
  height: 40px;
  overflow: hidden;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px;

  &_x {
    width: 50px;
    height: 100%;
    line-height: 37px;
    border-left: 1px solid #e2e2e2;
    color: #ccc;
    font-size: 30px;
    font-weight: 400;
  }

  &_input {
    flex: 1;
    height: 100%;
    background-color: #fff;
    color: #696969 !important;

    /deep/.el-input__inner {
      width: calc(100% - 42px);
      // width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #fff;
      color: #696969 !important;
      border: none;
      border-radius: 0px;
      padding: 0px;
    }
  }

  &_but {
    width: 58px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 17px;
      height: 17px;
    }
  }

  // &_title {
  //   width: 100px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }
}

.search_title {
  // width: 00px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.flex_align {
  display: flex;
  align-items: center;
}

#mouseOffset {
  color: red;
  font-size: 13px;
  background-color: rgb(255, 255, 255);
  padding: 0px 5px;
  border: 1px solid rgb(0, 0, 0);
}

/deep/.baidu_lAbel_triangle {
  width: 0px;
  border-style: solid;
  border-color: transparent;
  border-width: 10px 10px 8px 10px;
  border-top-color: var(--main-color);
  opacity: 1;
  margin: auto;
  position: absolute;
  left: 45%;
  bottom: -17px;
  z-index: 3;
}
</style>
