/*
 * @Author: LZH
 * @Date: 2025-03-11 09:16:42
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-03-14 11:13:37
 * @Description: 
 */
import filterMethod from "@/method/filterMethod.js";
// import global from "@/method/globalMethod.js";
import EventBus from "@/eventBus/eventBus.js";
import axios from "axios";
// import qs from "qs";
import beasconfig from "@/api/beasconfig.js";
const enclosureUrlapi = beasconfig.enclosureUrlapi;
// const gpsUrlapi = beasconfig.gpsUrlapi;
const enclosure = {
    data() {
        return {
            isEnclosure: false, // 是否显示围栏弹出框
            mapIconDisplay: false,
            polygon: null,
            dialogTitle: "",
            // temporaryTimeOne:null,   //30秒没有操作就调用地图刷新
            showArea: "",
            fenceId: 0,
        }
    },
    methods: {
        successEnclosure() {  //保存围栏弹出框
            this.isEnclosure = false;
        },
        cloneEnclosure() {       //关闭围栏弹出框
            this.isEnclosure = false;
        },
        /*围栏详情*/
        getFenceDetails() {
            if (this.fenceId > 0) {
                axios
                    .get(`${enclosureUrlapi}/electronicFence/${this.fenceId}`)
                    // axios.get(`/liang/electronicFence/${this.fenceId}`)
                    .then((res) => {
                        // var that = this;
                        // console.log("获取围栏详情", res);
                        var effectiveDates = [];
                        // var validPeriods = [];
                        var data = res.data.data;
                        this.fenceName = data.efName; //围栏名称
                        this.showArea = data.efRemark; //是否显示
                        this.triggerMethod = data.efType; //禁出 禁入
                        this.ddyId = data.ddyId; //调度员id
                        this.pointList = data.pointList; //获取经纬度坐标
                        this.pointListss = data.pointList; //获取经纬度坐标
                        let [startTime, endTime] = data.effectiveTimes.split("|"); //时段
                        this.$store.state.efRemark = data.efRemark;
                        if (data.validStartTime != 0) {
                            this.validStartTime = this.$moment(data.validStartTime).format("YYYY-MM-DD"); //有效日期
                            this.validEndTime = this.$moment(data.validEndTime).format("YYYY-MM-DD"); //有效日期结束
                        } else {
                            this.validStartTime = "";
                            this.validEndTime = "";
                        }
                        const dateTest = this.$moment(new Date()).format("YYYY-MM-DD");
                        // console.log("有时候无值", startTime, endTime);
                        if (
                            startTime == undefined ||
                            startTime == "" ||
                            endTime == undefined ||
                            endTime == ""
                        ) {
                            this.validPeriod = "";
                        } else {
                            startTime = dateTest + " " + startTime;
                            endTime = dateTest + " " + endTime;
                            this.validPeriod = [new Date(startTime), new Date(endTime)];
                        }

                        this.dayOfTheWeek = data.periodData.split(","); //星期几
                        this.dayOfTheWeek = this.dayOfTheWeek.map(function (data) {
                            return +data;
                        });
                        effectiveDates[0] = this.validStartTime;
                        effectiveDates[1] = this.validEndTime;
                        this.effectiveDate = effectiveDates;
                        /*画围栏*/
                        this.drawFence();
                        // console.log("日期", this.effectiveDate);
                        // console.log("时段", validPeriods);
                    });
            }
        },
        /*区域编辑*/
        locationData() {
            this.hintDivShow = true;
            this.createInformationWindow = false; //创建窗口
        },
        establish() {
            //创建
            this.overlays = { list: filterMethod.getPolygonDrop(this.polygon) };
            this.isShowMouse = "none";
            this.isEnclosure = true;
        },
        judgeType() {
            //判断是编辑还是创建
            if (this.fenceId > 0 && this.editRegion == 2) {
                this.dialogTitle = this.$t("message.Editfence");
                this.editRegion = 2;
            } else if (this.editRegion == 3) {
                this.dialogTitle = this.$t("message.Createfence");
                this.editRegion = 3;
            }
        },
        upFence(type, event) {
            if (this.editRegion != 1) {
                if (type == 1) {
                    this.$store.state.toUpdateEnclosure = 0;
                    this.search = "";
                    if (this.editRegion == 2) {
                        // console.log('用户已双击')
                        this.getEnclosure();
                    } else {
                        this.establish();
                    }
                } else if (type == 2) {
                    this.isShowMouse = "block";
                    this.offsetLeft = event.domEvent.offsetX - 15;
                    this.offsetTop = event.domEvent.offsetY - 30;
                }
            }
        },
        /*画围栏*/
        async drawFence() {
            var that = this;
            var list = [];
            let color;
            let fillColor;
            if (this.polygon) {
                this.polygon.setMap(null);
            }
            if (that.editRegion == 1) {
                color = "#0064fc";
                fillColor = "rgba(22,111,245,0.2)";
            } else {
                color = "#ff3c00";
                fillColor = "rgba(255,60,0,0.3)";
            }
            if (that.showArea == "2") {
                var pointLists = that.pointList.split(";");
                var pointLists1 = pointLists.join("|").split("|");
                await pointLists1.map((item) => {
                    var pt = item.split(",");
                    var pts = { lat: Number(pt[0]), lng: Number(pt[1]) };
                    list.push(pts);
                });
            } else {
                var pointLists = that.pointList.split("|");
                await pointLists.map((item) => {
                    var pt = item.split(",");
                    var pts = { lat: Number(pt[1]), lng: Number(pt[0]) };
                    list.push(pts);
                });
                const polygon = new google.maps.Polygon({
                    paths: list,
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: fillColor,
                    editable: that.editRegion != 1 ? true : false, // 设置为可编辑
                    fillOpacity: 0.35,
                });
                polygon.setMap(that.maps);
                google.maps.event.addListener(polygon, "dblclick", () => {
                    this.upFence(1);
                });
                google.maps.event.addListener(polygon, "mousemove", (event) => {
                    this.upFence(2, event);
                });
                google.maps.event.addListener(polygon, "mouseout", () => {
                    this.isShowMouse = "none";
                });
                that.polygon = polygon;
            }
            if (that.editRegion == 2) {
                that.locationData();
            }
            if (that.editRegion == 1) {
                let array = that.pointPosition;
                array.forEach((item1) => {
                    if (item1.pot) {
                        var pts;
                        if (this.mapType == "Wgs84") {
                            pts = { lat: Number(item1.pot.Lat), lng: Number(item1.pot.Lng) };
                        }
                        list.push(pts);
                    }
                });
            }
            setTimeout(function () {
                /*整体自适应居中*/
                that.setZoom(list, that.theFirstTime);
            }, 300);
        },
        getEnclosure() {
            //更新围栏
            let that = this;
            let polygon = this.polygon;
            if (polygon) {
                /*经纬度*/
                that.pointList = filterMethod.getPolygonDrop(polygon);
                console.log(filterMethod.getPolygonDrop(polygon), 1111)
            }
            let parmas = {
                companyId: parseInt(that.$store.state.userInfo.User_CompanyID),
                groupId: 0,
                efRemark: that.$store.state.efRemark, //省市区
                pointType: 0, //地图类型
                ddyId: parseInt(that.$store.state.userInfo.User_ID), // 调度员id
                pointList: that.pointList, //经纬度数据
            };
            if (that.fenceId > 0) {
                parmas.efId = parseInt(that.fenceId); //围栏id
            }
            axios.post(`${enclosureUrlapi}/electronicFence/save`, parmas).then((res) => {
                if (res.data.code == 200) {
                    that.clearOverwrites();
                    that.$store.state.toUpdateEnclosure = 2;
                    that.editRegion = 1;
                    that.fenceId = 0;
                    that.overlays = {};
                    that.isShowMouse = "none";
                    that.polygon.setMap(null);
                    that.$MessageSuccess(that.$t("message.message.update_successfully"));
                } else {
                    that.$MessageWarning(res.data.message);
                }
            });
        },
        polygonMap(list) {
            //绘制地图
            let that = this;
            setTimeout(() => {
                const polygon = new google.maps.Polygon({
                    paths: list,
                    strokeColor: "#ff3c00",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "rgba(255,60,0,0.3)",
                    editable: that.editRegion != 1 ? true : false, // 设置为可编辑
                    fillOpacity: 0.35,
                });
                polygon.setMap(that.maps);
                that.polygon = polygon;
                google.maps.event.addListener(polygon, "dblclick", () => {
                    this.upFence(1);
                });
                google.maps.event.addListener(polygon, "mousemove", (event) => {
                    this.upFence(2, event);
                });
                google.maps.event.addListener(polygon, "mouseout", () => {
                    this.isShowMouse = "none";
                });
            }, 310);
        },
    },
    created() {

    },
    beforeDestroy() {
        EventBus.$off("selectedEnclosure");
        EventBus.$off("editEnclosureMap");
    },
    mounted() {
        EventBus.$on("selectedEnclosure", (row, type) => {
            this.pointPosition = [];
            this.overlays = {};
            this.recordType = type;
            this.fenceId = row.efId;
            this.theFirstTime = 0;
            this.editRegion = 1;
            this.onmouseout();
            this.clearOverwrites();
            if (this.fenceId > 0 && row.children && row.children.length <= 0) {
                this.getFenceDetails(1);
            }
        });
        EventBus.$on("editEnclosureMap", (row) => {
            //编辑围栏
            this.theFirstTime = 0;
            this.fenceId = row.efId;
            this.dialogTitle = this.$t("message.Editfence");
            this.overlays = {};
            if (row) {
                this.editRegion = 2;
                this.onmouseout(1);
                this.getFenceDetails(2);
            }
        });
    },
    watch: {
        //监听事件
        "$store.state.groupOnline": {
            handler(array) {
                if (array && array.length > 0 && this.$store.state.navId == 2) {
                    let groupData = this.$store.state.selectedInfo;
                    if (groupData.length > 0) {
                        this.clearOverwrites();
                        let arr = groupData[0].children ? groupData[0].children : groupData;
                        let userArray = array[0].onlineArray;
                        userArray.forEach((item) => {
                            let index = arr.findIndex((one) => one.id == item.id);
                            if (index >= 0) {
                                if (groupData[0].children) {
                                    this.$store.state.selectedInfo[0].children[index] = item;
                                } else {
                                    this.$store.state.selectedInfo = [item];
                                }
                            }
                        });
                        // this.switchOnlineState(this.type);
                        this.preventFrequentlyRefresh();
                    }
                }
            },
        },
        "$store.state.toUpdateEnclosure": {
            handler(type) {
                if (type == 1 || type == 2) {
                    //1是创建围栏  2是编辑
                    this.clearOverwrites();
                }
            },
        },
        "$store.state.isMappingTool": {
            handler(bool) {
                if (this.$store.state.navId == 2 && bool) {
                    this.fenceId = 0;
                    this.editRegion = 3;
                    this.dialogTitle = this.$t("message.Createfence");
                    this.clearOverwrites();
                }
            },
        }
    }
}

export { enclosure };