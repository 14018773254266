/*
 * @Author: LZH
 * @Date: 2025-03-11 09:16:42
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-03-11 15:59:33
 * @Description: 
 */
import axios from "axios";
import qs from "qs";
import beasconfig from "@/api/beasconfig.js";
const regionUrlapi = beasconfig.regionUrlapi;
const regionGroup = {
    data() {
        return {
            pointList: "", //经纬度数据
        }
    },
    methods: {
        getRegionDetails() {
            axios
                .get(
                    `${regionUrlapi}-${this.$store.state.userState.server}/region/${this.$store.state.efId}`
                )
                .then((res) => {
                    if (res.data.code == 200) {
                        // console.log(res)
                        this.$store.state.efRemark = res.data.data.regionType;
                        this.$store.state.efId = res.data.data.regionId;
                        this.pointList = res.data.data.pointList;
                        this.clearOverwrites();
                        /*画围栏*/
                        this.drawFenceRegion();
                    } else {
                        this.$MessageWarning(res.data.message);
                    }
                });
        },
        /*画围栏*/
        async drawFenceRegion() {
            var that = this;
            var list = [];
            let color;
            let fillColor;
            if (that.editRegion == 1) {
                color = "#0064fc";
                fillColor = "rgba(22,111,245,0.2)";
            } else {
                color = "#ff3c00";
                fillColor = "rgba(255,60,0,0.3)";
            }
            if (that.$store.state.efRemark == "2") {
                var pointLists = that.pointList.split(";");
                var pointLists1 = pointLists.join("|").split("|");
                await pointLists1.map((item) => {
                    var pt = item.split(",");
                    var pts = { lat: Number(pt[0]), lng: Number(pt[1]) };
                    list.push(pts);
                });
            } else {
                var pointLists = that.pointList.split("|");
                await pointLists.map((item) => {
                    var pt = item.split(",");
                    var pts = { lat: Number(pt[1]), lng: Number(pt[0]) };
                    list.push(pts);
                });
                if (that.mapType == "Wgs84") {
                    // console.log(list)
                    const bermudaTriangle = new google.maps.Polygon({
                        map: that.maps,
                        paths: list,
                        strokeColor: color,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: fillColor,
                        fillOpacity: 0.35,
                        editable: true,
                    });
                    google.maps.event.addListener(
                        bermudaTriangle.getPath(),
                        "insert_at",
                        function (evt) {
                            //监听多边形是否修改了数据
                            console.log(evt);
                            // let arr = bermudaTriangle.getPath();
                            // console.log(bermudaTriangle.getPath())
                            // console.log(bermudaTriangle.getPath().getAt(evt))
                            // console.log(bermudaTriangle.getPath().getAt(evt).toUrlValue(6))
                        }
                    );
                    google.maps.event.addListener(
                        bermudaTriangle.getPath(),
                        "remove_at",
                        function (evt) {
                            //监听多边形是否修改了数据
                            console.log(evt);
                            // let arr = bermudaTriangle.getPath();
                            // console.log(bermudaTriangle.getPath())
                        }
                    );

                    this.graphsDrawn = bermudaTriangle;
                }
            }
            if (that.editRegion == 1) {
                let array = that.pointPosition;
                array.forEach((item1) => {
                    if (item1) {
                        var pts = new BMap.Point(item1.lng, item1.lat);
                        list.push(pts);
                    }
                });
            }
            setTimeout(function () {
                /*整体自适应居中*/
                that.setZoom(list, that.theFirstTime);
            }, 300);
        },
        getEnclosure() {
            //更新区域群组
            let that = this;
            let array = this.overlays;
            let pointList = "";
            if (array) {
                // this.coordinateCalculationArea(array);
                that.pointList = "";
                for (var i = 0; i < array.length; i++) {
                    var lat = array[i].lat;
                    var lng = array[i].lng;
                    if (pointList == null || pointList == "") {
                        pointList = lng + "," + lat;
                    } else {
                        pointList = pointList + "|" + lng + "," + lat;
                    }
                }
                /*经纬度*/
                that.pointList = pointList;
            }
            let parmas = {
                regionType: that.$store.state.efRemark, //类型 1自定义 2区域
                pointList: that.pointList, //经纬度数据
            };
            if (that.$store.state.efId > 0) {
                parmas.regionId = parseInt(that.$store.state.efId); //围栏id
            }
            axios
                .post(
                    `${regionUrlapi}-${that.$store.state.userState.server}/extregion/changePointList`,
                    qs.stringify(parmas),
                    {
                        headers: beasconfig.tokenSl,
                    }
                )
                .then((res) => {
                    if (res.data.code == 200) {
                        that.clearOverwrites();
                        that.emptyData();
                        that.$MessageSuccess(that.$t("message.message.update_successfully"));
                        setTimeout(() => {
                            that.switchOnlineState(that.type);
                        }, 300);
                    } else {
                        that.$MessageWarning(res.data.message);
                    }
                });
        },
    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    watch: {

    }
}

export { regionGroup };