/*
 * @Author: LZH
 * @Date: 2025-03-11 09:16:42
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-03-11 09:20:49
 * @Description: 
 */
const drawGraphic = {
    data() {
        return {
           
        }
    },
    methods: {
        
    },
    created() {
        
    },
    beforeDestroy() {
        
    },
    mounted() {
        
    },
    watch: {
       
    }
}

export { drawGraphic };