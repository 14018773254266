import moment from "moment";

const deweight = (arr) => {
    //数组去重
    let de_arr = [];
    arr.forEach((i) => {
        let isTrue = de_arr.every((j) => j.id != i.id);
        isTrue ? de_arr.push(i) : "";
    });
    return de_arr;
}

const InspectionTime = (time) => {
    //验证时间
    let Rtime = moment(time).valueOf(); //定位上报时间
    let Dtime = moment(new Date()).valueOf(); //当前时间
    let Ztime = 60 * 1000 * 5; //5分钟
    let Mtime = Dtime - Rtime; //当前时间减去定位上报时间
    let isExceed = Mtime >= Ztime; //判断是否超过五分钟
    return isExceed;
}

const getPolygonDrop = (event) => {
    let point = "";
    event.getPath().forEach(function (latLng) {
        let poit = latLng.toString().replace(/[\(\)]/g, "");
        let poits = poit.split(",");
        if (point == "" || point == null) {
            point = `${poits[1]},${poits[0]}`;
        } else {
            point = `${point}|${poits[1]},${poits[0]}`;
        }
    });
    return point;
}

export default { deweight, InspectionTime, getPolygonDrop }