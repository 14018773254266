// import { mapState } from "vuex";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { getQueryUserIcon } from "@/administration/upmarker.js";
import filterMethod from "@/method/filterMethod.js";
import global from "@/method/globalMethod.js";
import { _throttle } from "@/method/utils.js";
import EventBus from "@/eventBus/eventBus.js";
import axios from "axios";
import qs from "qs";
import beasconfig from "@/api/beasconfig.js";
import { regionGroup } from './regionGroup'
import { enclosure } from './enclosure'
import m0 from "@/assets/map/m0.png";
import m1 from "@/assets/map/m1.png";
import m2 from "@/assets/map/m2.png";
import m3 from "@/assets/map/m3.png";

const gpsUrlapi = beasconfig.gpsUrlapi;
const googlemap = {
    mixins: [regionGroup, enclosure],
    data() {
        return {
            type: 1,
            isShow: true,
            overlays: {},
            coordinate: [], //全部对讲用户
            distinguishType: 0,
            theFirstTime: 0, //初次
            frequency: 0, //次数
            temporaryTime: null, //计时器
            search: "",
            recordType: 0, //记录类型
            groupData: [],
            pointPosition: [],
            searchLocation: [],
            isBool: false, //显示编辑弹出框
            editRegion: 1, //编辑区域
            dialogTitle: this.$t("message.Createregionalgroups"),
            offsetLeft: 0,
            offsetTop: 0,
            isShowMouse: "none",
            mapType: "Wgs84", //切换百度谷歌
            msgTipData: {
                showcontent: false, //是否显示
                tipstext: "", //文字提示
            },
            maps: null,
            timeNum: 0, //判断谷歌地图是否加载完成
            gooleWc: false, //谷歌地图是否加载完成
            mapTrafficLayer: null, //路况图层
            googleDraw: null, //谷歌绘制
            graphsDrawn: null, //绘制的图形
            googleDistanceTool: null, //谷歌地图测距功能
            rangeArr: [], //测距线条数组
            rangeCompleto: [], //测距完成的数组
            rangeCompletoArr: {}, //测距多个数组
        }
    },
    beforeDestroy() {
        this.clearTimer();
        this.frequency = 0;
        this.theFirstTime = 0;
        this.$store.state.efRemark = "1";
        EventBus.$off("position");
        EventBus.$off("changeMapOne");
        EventBus.$off("editRegionGroupMap");
        EventBus.$off("emptyMap");
        EventBus.$off("mouseout");
        EventBus.$off("googlemapout");
    },
    methods: {
        // 方法集合
        init() {
            let that = this;
            EventBus.$on("position", function (point) {
                //当前位置
                if (point) {
                    that.maps.setCenter({ lat: Number(point.lat), lng: Number(point.lng) });
                } else {
                    if (that.pointPosition.length > 0) {
                        that.setZoom(that.pointPosition, that.theFirstTime);
                    } else {
                        that.maps.setCenter({ lat: 38.903804230612835, lng: -77.0365677652717 });
                    }
                    that.search = "";
                }
            });
            EventBus.$on("editRegionGroupMap", function (row) {
                //编辑区域群组
                that.clearTimer();
                that.theFirstTime = 0;
                that.$store.state.efId = row.regionId;
                if (row.regionId) {
                    that.editRegion = 2;
                    that.onmouseout(1);
                    that.getRegionDetails();
                }
            });
            EventBus.$on("changeMapOne", function (row, type) {
                //选中名称
                // that.clearTimer();
                that.recordType = type;
                that.$store.state.selectedInfo = row == "" ? [] : [row];
                that.theFirstTime = 0;
                that.$store.state.efId = 0; //每次点击清空
                that.editRegion = 1;
                if (row.regionId) {
                    that.$store.state.efId = row.regionId;
                }
                that.onmouseout();
                if (that.$store.state.efId > 0 && row.children && row.children.length <= 0) {
                    that.getRegionDetails();
                }
            });
            EventBus.$on("emptyMap", function (type) {
                //清除地图覆盖物
                that.emptyData();
                if (type == 1) {
                    //类型等于回退到最开始
                    that.editRegion = 1;
                } else {
                    that.editRegion = type == 2 ? 1 : 3; //2是删除区域群组
                }
                console.log(that.editRegion)
                that.theFirstTime = 0;
                that.onmouseout();
                that.clearOverwrites();
            });
            EventBus.$on("mouseout", function () {
                //清除地图覆盖物
                that.onmouseout();
            });
        },
        onmouseout(type) {
            let that = this;
            if (that.distinguishType > 0) {
                that.isShowMouse = "none";
                that.distinguishType = 0;
                that.$store.state.isCustom = false;
                if (type != 1) {
                    //不等于的话需要刷新
                    setTimeout(() => {
                        that.switchOnlineState(that.type);
                    }, 300);
                }
            } else {
                that.switchOnlineState(that.type);
            }
        },
        //谷歌地图
        async initMap() {
            if (window.googleMap) {
                this.timeNum = 0;
                try {
                    this.maps = new google.maps.Map(document.getElementById("googlemap"), {
                        zoom: 10,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        //地图中心点，这里我以第一个数据的经纬度来设置中心点
                        center: { lat: 38.903804230612835, lng: -77.0365677652717 },
                        disableDefaultUI: false,
                        zoomControl: false,
                        maxZoom: 17, //18
                        minZoom: 3,
                        disableDoubleClickZoom: true,
                        mapId: "4504f8b37365c3d0",
                        draggableCursor: "auto",
                    });
                    this.mapTrafficLayer = new google.maps.TrafficLayer();
                    const renderer = {
                        render({ count, position }) {
                            // 创建聚合图标DOM元素
                            const getColor = (count) => {
                                let url = m0;
                                let calss = "m0";
                                if (count <= 500) {
                                    url = m0;
                                    calss = 'mo';
                                } else if (count >= 500 && count <= 1000) {
                                    url = m1;
                                    calss = 'm1';
                                } else if (count > 1000 && count <= 2000) {
                                    url = m2;
                                    calss = 'm2';
                                } else {
                                    url = m3;
                                    calss = 'm3';
                                }
                                return {
                                    url,
                                    calss
                                };
                            }
                            const div = document.createElement("div");
                            div.className = "cluster-icon";
                            div.innerHTML = `
                              <div class="circle ${getColor(count).calss}" style="background:url(${getColor(count).url});background-size: 100% 100%;">
                                ${count}
                              </div>
                            `;
                            return new google.maps.marker.AdvancedMarkerElement({
                                position,
                                content: div
                            });

                        }
                    };
                    this.distanceMarkers = [];
                    this.googleMarkerClusterer = new MarkerClusterer({
                        map: this.maps,
                        markers: [],
                        renderer,
                    }); //聚合
                    // 保存测量的路径
                    var time = null;
                    // 创建一个标签来显示总距离
                    // 监听地图的点击事件，添加测量点
                    google.maps.event.addListener(this.maps, "click", (event) => {
                        // console.log(path.getArray())
                        if (this.distinguishType == 5) {
                            var path = this.googleDistanceTool.getPath();
                            time = setTimeout(() => {
                                //点击事件跟双击事件有冲突所以需要给个延迟
                                path.push(event.latLng); // 添加点到路径中
                                this.rangeArr.push(event.latLng);
                                //直接调用API接口来计算几条线段的长度和 单位米
                                let arr = path.getArray();
                                var distanceValue = google.maps.geometry.spherical.computeLength(arr);
                                distanceValue = `<div class="ranging">${(distanceValue / 1000).toFixed(2)}Km</div>`;
                                var starthtml = `<div class="ranging">${this.$t("message.startingpoint")}</div>`;
                                let rounddot = this.googleMarkerLabel({
                                    position: arr[arr.length - 1],
                                    html: `<div class='cj_circular mouse_dw'></div>`,
                                }); //圆形
                                let start = this.googleMarkerLabel({
                                    position: arr[arr.length - 1],
                                    html:
                                        path.getArray().length > 1
                                            ? distanceValue
                                            : starthtml,
                                }); //圆形
                                this.rangeCompleto.push(rounddot, start);
                            }, 200);
                        }
                    });
                    // 添加双击事件监听器
                    google.maps.event.addListener(this.maps, "dblclick", () => {
                        clearTimeout(time);
                        // 获取双击事件的经纬度
                        // const latLng = event.latLng;
                        // // 在控制台输出双击事件的经纬度
                        // console.log("Double click at: " + latLng.lat() + ", " + latLng.lng());
                        // this.googleDistanceTool.end();
                        if (this.distinguishType == 5) {
                            this.rangingTotalLength();
                        }
                    });
                    // 监听鼠标右键点击事件
                    google.maps.event.addListener(this.maps, "rightclick", () => {
                        // 获取点击位置的经纬度
                        if (this.distinguishType == 5) {
                            if (this.rangeArr.length > 1) {
                                this.rangingTotalLength();
                            } else {
                                this.cleanupDraw(); //先清除
                            }
                        }
                    });
                    this.gooleWc = true;
                } catch (error) {
                    this.gooleWc = false;
                    // console.log("需要翻墙")
                }
            } else {
                setTimeout(() => {
                    this.timeNum += 1;
                    if (this.timeNum <= 10) {
                        this.initMap();
                    }
                }, 1000);
            }
        },
        rangingTotalLength() {
            //测距保存
            if (this.rangeArr.length > 0) {
                let marker = this.rangeCompleto[this.rangeCompleto.length - 1]; //把最后一个添加的给删除
                this.rangeCompleto.splice(this.rangeCompleto.length - 1, 1); //删除最后一个重新添加
                // let leng = this.rangeCompletoArr.length + 1;
                marker.setMap(null);
                var path = this.googleDistanceTool.getPath();
                let arr = path.getArray();
                var distanceValue = google.maps.geometry.spherical.computeLength(arr);
                distanceValue = `<div class="ranging_total">${this.$t("message.totallength")}：${(
                    distanceValue / 1000
                ).toFixed(2)}Km</div>`;
                let end = this.googleMarkerLabel({
                    position: arr[arr.length - 1],
                    html: distanceValue,
                });
                var pint = this.$moment(new Date()).valueOf();
                let close = this.googleMarkerLabel({
                    position: arr[arr.length - 1],
                    html: `<div class="close_range" onclick="closeLabel(${pint},2,event)" >×</div>`,
                });
                this.rangeCompleto.push(end, close); //保存当前的数组
                var line = new google.maps.Polyline({
                    path: this.rangeArr,
                    strokeColor: "#FF0000", // 线条颜色
                    strokeOpacity: 1.0, // 线条透明度
                    strokeWeight: 2, // 线条宽度
                    map: this.maps, // 绑定地图实例
                });
            }
            this.rangeCompletoArr[pint] = {
                line: line,
                marker: this.rangeCompleto,
            };
            this.googleDistanceTool.setMap(null);
            this.rangeArr = []; //绘制的线
            this.rangeCompleto = [];
            this.distinguishType = 0;
            this.maps.setOptions({ draggableCursor: `auto` }); //设置谷歌地图鼠标图标
        },
        googleMarkerLabel(mark) {
            //谷歌label
            if (mark) {
                const markerLabel = global.addHtml(1, {
                    map: this.maps,
                    content: mark.html,
                    position: mark.position
                });
                return markerLabel;
            }
        },
        // 根据点的数组自动调整缩放级别
        setZoom(bPoints, type) {
            if (type == 1) {

                return
            }
            if (this.mapType == 'Wgs84') {
                if (this.maps && bPoints.length > 0) {
                    var latlngbounds = new google.maps.LatLngBounds();  //根据点的数组自动调整缩放级别
                    bPoints.forEach(item => {
                        let lat = item.pot ? item.pot.Lat : item.lat;
                        let lng = item.pot ? item.pot.Lng : item.lng;
                        latlngbounds.extend(new google.maps.LatLng(lat, lng));
                    })
                    this.maps.fitBounds(latlngbounds);
                    this.maps.panToBounds(latlngbounds);
                }
            }
        },
        startDraw() {        //绘制测距开始
            // 创建测距工具
            this.cleanupDraw(); //先清除
            this.googleDistanceTool = new google.maps.Polyline({
                strokeColor: "#FF0000", // 线条颜色
                strokeOpacity: 1.0, // 线条透明度
                strokeWeight: 2, // 线条宽度
                map: this.maps, // 绑定地图实例
            });
            this.maps.setOptions({ draggableCursor: `crosshair` }); //设置谷歌地图鼠标图标
        },
        cleanupDraw() {
            //清除测距功能
            if (this.rangeArr.length > 0) {
                //如果是绘制的时候点直接清除
                this.rangeCompleto.forEach((item) => item.setMap(null));
                this.googleDistanceTool.setMap(null);
                this.rangeArr = [];
                this.distinguishType = 0;
                this.maps.setOptions({ draggableCursor: `auto` }); //设置谷歌地图鼠标图标
            }
        },
        eliminateCheck(type) {
            //清除绘制选中
            if (this.distinguishType != 0) {
                if (type != 1) {
                    this.clearOverwrites();
                }
                this.$store.state.isCustom = false;
                this.distinguishType = 0;
            }
        },
        /*绘制操作*/
        drawShape() {
            //绘制地图形状
            let that = this;
            if (that.distinguishType != 4) {
                // that.$store.state.isCustom = false;
                that.mapIconDisplay = false;
                that.clearTimer();
            }
            if (this.googleDraw) {
                this.googleDraw.setMap(null); //停止绘制
            }
            let color;
            let fillColor;
            let strokeWeight = 1;
            let bool = that.editRegion == 1;
            if (bool) {
                color = "#0064fc";
                fillColor = "rgba(22,111,245,0.2)";
            } else {
                color = "#ff3c00";
                fillColor = "rgba(255,60,0,0.3)";
            }
            let index = that.distinguishType - 1;
            if (this.mapType == "Wgs84") {
                let drawingOptions = [
                    {
                        polygonOptions: "", //多边形
                    },
                    {
                        circleOptions: "", //圆形
                    },
                    {
                        rectangleOptions: "",
                    },
                    {
                        markerOptions: "",
                    },
                    {
                        polylineOptions: "",
                    },
                ];
                let modeArr = [
                    google.maps.drawing.OverlayType.POLYGON,
                    google.maps.drawing.OverlayType.CIRCLE,
                    google.maps.drawing.OverlayType.RECTANGLE,
                    google.maps.drawing.OverlayType.MARKER,
                    google.maps.drawing.OverlayType.POLYLINE,
                ];
                let opions = drawingOptions[index];
                let key = Object.keys(opions)[0];
                if (index == 4) {
                    color = "#FF0000";
                    fillColor = "#FF0000";
                    strokeWeight = 2;
                }
                opions[key] = {
                    strokeColor: color,
                    fillColor: fillColor,
                    // fillOpacity: "var(--main-color)",
                    strokeWeight: strokeWeight,
                    clickable: false,
                    editable: false,
                    strokeStyle: "dashed",
                    zIndex: 1,
                    icon: require("@/assets/map/mark.png"), // 初始图标路径
                };

                that.googleDraw = new google.maps.drawing.DrawingManager({
                    drawingMode: modeArr[index],
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: ["polygon"],
                    },
                    ...opions,
                });
                google.maps.event.addListener(
                    that.googleDraw,
                    "polylinecomplete",
                    function (polyline) {
                        //测距功能
                        var pointxy = polyline.getPath();
                        var triangleCoords = [];
                        for (var i = 0; i < pointxy.length; i++) {
                            var pointstemp = new google.maps.LatLng(
                                pointxy.getAt(i).lat(),
                                pointxy.getAt(i).lng()
                            );
                            triangleCoords.push(pointstemp);
                        }
                        new google.maps.Polyline({
                            path: triangleCoords,
                            map: that.maps,
                            strokeColor: "#FF0000",
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                        });
                        //直接调用API接口来计算几条线段的长度和 单位米
                        var distanceValue = google.maps.geometry.spherical.computeLength(
                            triangleCoords
                        );
                        distanceValue = `<div class="ranging">${this.$t("message.totallength")}：${(
                            distanceValue / 1000
                        ).toFixed(2)}Km</div>`;
                        this.googleMarkerLabel({
                            position: triangleCoords[triangleCoords.length - 1],
                            html: distanceValue,
                        });
                    }
                );
                google.maps.event.addListener(
                    that.googleDraw,
                    "markercomplete",
                    function (event) {
                        //自定义标注。
                        var position = event.position;
                        var latLng = { lat: position.lat(), lng: position.lng() };
                        that.mark = latLng;
                        that.setMarkImg(that.mark);
                        that.dialogModifyMark = true;
                    }
                );
                google.maps.event.addListener(
                    that.googleDraw,
                    "circlecomplete",
                    function (circle) {
                        //用户刚刚绘制的圆形。
                        var radius = circle.getRadius();
                        let userList = [];
                        // let bounds = circle.getBounds();
                        let array = filterMethod.deweight(that.coordinate);
                        that.gooleCover.forEach((marker) => {
                            let mark = marker.position;
                            let bool = google.maps.geometry.spherical.computeDistanceBetween(
                                mark,
                                circle.getCenter()
                            ); //判断当前用户是否被框选
                            let row = array.find((item) => item.id == marker.id);
                            if (bool <= radius && row) {
                                //判断覆盖物是否在矩形内
                                userList.push(row);
                            }
                        });
                        that.temporaryGroup(userList);
                    }
                );
                google.maps.event.addListener(
                    that.googleDraw,
                    "polygoncomplete",
                    function (event) {
                        //用户刚刚绘制的多边形。
                        let arr = [];
                        event.getPath().forEach(function (latLng) {
                            let poit = latLng.toString().replace(/[\(\)]/g, "");
                            let poits = poit.split(",");
                            // arr.push(new google.maps.LatLng(poit));
                            arr.push({
                                lat: Number(poits[0]),
                                lng: Number(poits[1]),
                            });
                            // arr.push(new google.maps.LatLng(latLng.toString().replace(/[\(\)]/g, "")));
                        });
                        that.isUserRegion({
                            event: event,
                            arr: arr
                        }, 'polygon');
                    }
                );

                google.maps.event.addListener(
                    that.googleDraw,
                    "rectanglecomplete",
                    function (event) {
                        //用户刚刚绘制的矩形
                        var bounds = event.getBounds();
                        var ne = bounds.getNorthEast(); // 东北角
                        var sw = bounds.getSouthWest(); // 西南角
                        // var start = bounds.getNorthEast();
                        // var end = bounds.getSouthWest();
                        // var center = bounds.getCenter();
                        that.isUserRegion({
                            event: event,
                            arr: [
                                {
                                    lat: sw.lat(),
                                    lng: sw.lng(),
                                },
                                {
                                    lat: ne.lat(),
                                    lng: sw.lng(),
                                },
                                {
                                    lat: ne.lat(),
                                    lng: ne.lng(),
                                },
                                {
                                    lat: sw.lat(),
                                    lng: ne.lng(),
                                },
                                {
                                    lat: sw.lat(),
                                    lng: sw.lng(),
                                },
                            ]
                        }, 'rectangle');
                    }
                );

                google.maps.event.addListener(
                    that.googleDraw,
                    "overlaycomplete",
                    function (event) {
                        that.graphsDrawn = event.overlay; //绘制好的图形
                        // event.overlay.setMap(null);  //设置为空就是清除绘制
                        // that.googleDraw.setMap(null);   //停止绘制
                        setTimeout(() => {
                            that.closeGraphical();
                        }, 300);
                        if (that.editRegion == 1) {
                            that.startTime();
                        }
                    }
                );

                that.googleDraw.setMap(that.maps);
            }
        },
        isUserRegion(obj, type) {
            let rectangle = obj.event.getBounds;
            if (this.editRegion == 1) {
                let userList = [];
                let array = filterMethod.deweight(this.coordinate);
                this.gooleCover.forEach((marker) => {
                    let mark = marker.position;
                    let contains = {
                        'polygon': !rectangle ? google.maps.geometry.poly.containsLocation(mark, obj.event) : '',
                        'rectangle': rectangle ? obj.event.getBounds().contains(mark) : ''
                    };
                    let bool = contains[type]; //判断当前用户是否被框选
                    let row = array.find((item) => item.id == marker.id);
                    if (bool && row) {
                        //判断覆盖物是否在矩形内
                        userList.push(row);
                    }
                });
                this.temporaryGroup(userList);
            } else {
                let drop = {
                    'polygon': !rectangle ? filterMethod.getPolygonDrop(obj.event) : '',
                    'rectangle': rectangle ? global.getRectangleDrop(obj.event) : ''
                }
                this.overlays = { list: drop[type] };
                this.polygonMap(obj.arr); //添加绘制的图形
                this.judgeType();
                this.$store.state.efRemark = "1";
                EventBus.$emit("emptyChoice");
                this.msgTipData.tipstext = this.$t("message.Areyousuretosavethefence");
                this.hintDivShow = false;
            }
        },
        /*绘制入口*/
        openDrawing() {
            this.clearOverwrites();
            this.$store.state.isCustom = false;
        },
        /*画多边形*/
        drawPolylines() {
            this.clearFence();
            this.distinguishType = 1;
            this.drawShape();
        },
        /*画圆圈*/
        drawCircle() {
            this.clearFence();
            this.distinguishType = 2;
            this.drawShape();
        },
        /*画矩形*/
        drawRectangle() {
            this.clearFence();
            this.distinguishType = 3;
            this.drawShape();
        },
        /*返回上一步*/
        previousStep() {
            if (this.editRegion == 1 && this.distinguishType == 0) {

                return;
            }
            this.closeGraphical(1);
            if (this.editRegion != 1) {
                this.editRegion = 1;
                this.emptyData();
            }
        },
        closeGraphical(type) {
            //关闭图形
            if (this.editRegion != 1) {
                this.clearOverwrites();
            }
            if (type == 1) {
                this.theFirstTime = 0;
                this.onmouseout();
                this.mapIconDisplay = false;
            } else {
                this.$store.state.isCustom = false;
            }
            this.distinguishType = 0;
            if (this.graphsDrawn) {
                this.graphsDrawn.setMap(null); //设置为空就是清除绘制
            }
            if (this.googleDraw) {
                this.googleDraw.setMap(null); //停止绘制
            }
        },
        /*删除围栏区域*/
        deleteFenceArea() {
            if (this.editRegion == 1 && this.distinguishType == 0) {
                return;
            }
            this.closeGraphical(1);
            this.$store.state.isCustom = false;
            if (this.editRegion != 1) {
                this.editRegion = 1;
                this.emptyData();
            }
        },
        clearFence() {       //判断是否是围栏
            if (this.editRegion != 1) {
                if (this.$store.state.navId == 2) {
                    this.judgeType();
                }
                this.clearOverwrites();
            }
        },
        /*清除覆盖*/
        clearOverwrites() {
            if (this.polygon) {
                this.polygon.setMap(null);
            }
            if (this.graphsDrawn && this.distinguishType == 0) {
                this.graphsDrawn.setMap(null);
            }
        },
        // 绘制结束
        temporaryGroup(arr) {
            //呼叫临时群组
            if (arr.length > 0 && this.editRegion == 1) {
                if (this.type != 2) {
                    EventBus.$emit("temporaryGroup", arr);
                }
            } else {
                this.$MessageWarning(this.$t("message.message.Unabletocreatetemporarygroup"));
            }
        },
        // 搜索方法
        eliminate() {
            //清除
            this.search = "";
            this.searchLocation = [];
        },
        querySearchAsync(queryString, cb) {
            let that = this;
            if (queryString != "" && queryString != null) {
                if (that.mapType == "Wgs84") {
                    // 创建 AutocompleteService 对象
                    var autocompleteService = new google.maps.places.AutocompleteService();
                    // 创建 Autocomplete 对象
                    autocompleteService.getPlacePredictions(
                        { input: queryString },
                        function (predictions, status) {
                            let arr = [];
                            if (status === google.maps.places.PlacesServiceStatus.OK) {
                                predictions.forEach((item) => (item.title = item.description));
                                arr = predictions;
                                cb(arr);
                            } else {
                                cb(arr);
                            }
                        }
                    );
                }
            }
        },
        query() {
            if (this.search != "" && this.search != null) {
                if (this.mapType == "Wgs84") {
                    this.maps.setCenter(this.searchLocation[0]);
                }
            } else {
                this.searchLocation = [];
            }
            EventBus.$emit("emptyChoice");
        },
        handleSelect(item) {
            let that = this;
            that.search = item.title;
            if (that.mapType == "Wgs84") {
                // that.searchLocation = [item.point]; //搜索选中的定位信息
                // 使用 Geocoder 获取地点的详细信息
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({ placeId: item.place_id }, function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        let location = results[0].geometry.location;
                        that.maps.setCenter(location);
                        that.searchLocation = [location];
                    }
                });
            }
        },
        // 搜索结束
        // 地图操作方法
        changeMap(type, show) {
            //改变地图状态
            this.closeGraphical();
            if (type == 1) {
                //显示
                this.isShow = show;
                this.removeMarkers();
                let selectedInfo = this.$store.state.selectedInfo;
                if (show) {
                    this.$store.state.recordLable = []; //如何点击从false为true就把记录删除Lable的值给回到最初。
                }
                if (
                    this.$store.state.efId > 0 &&
                    selectedInfo.length > 0 &&
                    selectedInfo[0].children &&
                    selectedInfo[0].children.length <= 0
                ) {
                    this.isFence();
                } else {
                    this.switchOnlineState(this.type);
                }
            } else if (type == 2) {
                // 谷歌
                this.maps.setMapTypeId(
                    show ? google.maps.MapTypeId.ROADMAP : google.maps.MapTypeId.SATELLITE
                ); //正常  卫星
            } else if (type == 3) {
                if (this.mapTrafficLayer.getMap() && !show) {
                    // 如果路况图层已经添加到地图上，则移除它
                    this.mapTrafficLayer.setMap(null);
                } else {
                    // 如果路况图层还没有添加到地图上，则添加它
                    this.mapTrafficLayer.setMap(this.maps);
                }
            } else if (type == 4) {
                if (show) {
                    //放大
                    this.maps.setZoom(this.maps.zoom + 1);
                } else {
                    //缩小
                    this.maps.setZoom(this.maps.zoom - 1);
                }
            } else if (type == 5) {
                //测距
                if (this.mapType == "Wgs84") {
                    this.cleanupDraw(); //先清除
                    this.startDraw();
                    this.distinguishType = 5;
                }
            } else if (type == 6) {
                //标注
                this.cleanupDraw(); //先清除
                this.distinguishType = 4;
                this.drawShape();
            }
        },
        isFence() {      //判断围栏还是群组
            if (this.fenceId > 0 || this.$store.state.efId > 0) {
                let navId = this.$store.state.navId;
                switch (navId) {
                    case 1:
                        this.getRegionDetails();
                        break;
                    case 2:
                        this.getFenceDetails();
                        break;
                }
            }
        },
        /*用户id获取定位点*/
        getAnchorPoint(uid, users) {
            var that = this;
            let uids = uid.join(",");
            var params = {
                Uids: uids, //再线成员用户id
                CorrdinateType: that.mapType,
            };
            if (uid.length > 0) {
                getQueryUserIcon({
                    orgId: this.$store.state.userInfo.org_id,
                    userIds: uids,
                }).then((row) => {
                    let imgs = row.data.data;
                    axios
                        .post(
                            gpsUrlapi + "/shanli/gps/api/locations/LastLocation?",
                            qs.stringify(params),
                            {
                                headers: beasconfig.tokenSl,
                            }
                        )
                        .then((res) => {
                            // console.log("定位返回", res);
                            if (res.data.Data && res.data.Data.length > 0) {
                                var locationData = res.data.Data;
                                for (var i = 0; i < users.length; i++) {
                                    for (var j = 0; j < locationData.length; j++) {
                                        if (users[i].id == locationData[j].Uid) {
                                            // console.log("id----1111", users[i].id);
                                            users[i].pot = locationData[j];
                                        }
                                    }
                                }
                                var gps = {}; //找出相同的定位点
                                users.forEach(function (item, index) {
                                    let n = imgs.find((m) => m.user_id == item.id);
                                    if (item.pot) {
                                        if (n) {
                                            item.imgTabId = n.type;
                                            item.iId = n.iId;
                                            item.img = n.img;
                                        }
                                        var pint = `${item.pot.Lng}${item.pot.Lat}`;
                                        if (gps[pint]) {
                                            //判断是否有这个数组
                                            gps[pint].push(item);
                                        } else {
                                            gps[pint] = [item];
                                        }
                                        if (that.type == 1) {
                                            that.coordinate.push(item);
                                        }
                                        var pt;
                                        if (that.mapType == "Wgs84") {
                                            pt = item;
                                        }
                                        that.pointPosition.push(pt);
                                        item.pot.Rtime = that
                                            .$moment(Number(item.pot.Rtimestamp))
                                            .format("YYYY-MM-DD HH:mm:ss");
                                        item.isExceed =
                                            item.online == 3 ? filterMethod.InspectionTime(item.pot.Rtime) : false;
                                        item.czIndex = index; //用来判断层级优先级显示
                                    }
                                });
                                for (const key in gps) {
                                    that.setMarker(gps[key]);
                                }
                                if (that.$store.state.selectedInfo.length > 0) {
                                    that.isFence();
                                } else {
                                    that.setZoom(that.pointPosition, that.theFirstTime);
                                }
                            } else {
                                that.isFence();
                                if (that.recordType == 1) {
                                    that.nullMessage();
                                }
                            }
                        });
                });
            } else {
                that.isFence();
                if (that.recordType == 1) {
                    that.nullMessage();
                }
            }
        },
        // 用户id获取定位点结束
        startTime() {
            //30s刷新地图
            //开始计时
            let that = this;
            that.clearTimer();
            if (that.editRegion != 1) {
                return;
            }
            function timerTow() {
                return setTimeout(() => {
                    if (that.editRegion == 1) {
                        that.preventFrequentlyRefresh();
                    }
                }, 30000);
            }
            that.temporaryTime = timerTow();
        },
        clearTimer() {
            if (this.temporaryTime != null) {
                clearInterval(this.temporaryTime); // 清除定时器
                this.temporaryTime = null;
            }
        },
        selectedBall() {
            //选择半球
            this.switchOnlineState(this.type);
        },
        preventFrequentlyRefresh: _throttle(function () {
            //阻止频繁刷新
            this.switchOnlineState(this.type);
        }, 1000),
        // 地图操作方法结束
        nullMessage: _throttle(function () {
            //没数据的时候提示
            this.recordType = 0;
            this.$MessageWarning(this.$t("message.noData"));
        }, 500),
        emptyData() {
            //清空data值
            if (this.editRegion != 1) {
                this.editRegion = 1;
            }
            this.fenceId = 0;
            this.search = "";
            this.recordType = 0; //记录类型
            this.pointPosition = [];
            this.$store.state.selectedInfo = [];
            this.dialogTitle = this.$store.state.navId == 2 ? this.$t("message.Createfence") : this.$t("message.Createregionalgroups");
            this.$store.state.efId = 0;
            this.offsetLeft = 0;
            this.offsetTop = 0;
            this.isShowMouse = "none";
            this.overlays = {};
            this.$store.state.efRemark = "1";
        },
    },
    created() {

    },
    mounted() {
        this.initMap();
        setTimeout(() => {
            this.init();
        }, 500);
    },
    watch: {
        "$store.state.navId": {
            handler() {
                console.log('emptyData')
                this.clearOverwrites();
                this.cleanupDraw();
                this.deleteFenceArea();
                this.emptyData();
                this.preventFrequentlyRefresh();
            }
        },
    }
}

export { googlemap };